const faqs = [
  {
    question: "Documentación y permisos para viajar a Colombia",
    answer: (
      <div>
        <p>-  Pasaporte: El requisito fundamental es contar con un pasaporte válido. Asegúrate de que el tuyo tenga una vigencia mínima de seis meses a partir de la fecha de ingreso a Colombia. Si tu pasaporte está próximo a vencer, es recomendable renovarlo antes de iniciar tu viaje.</p><br/>
        <p>- Visa: Los ciudadanos mexicanos no necesitan obtener una visa para viajar a Colombia por turismo o negocios, siempre que la estadía no supere los 90 días. Existe una política de exención de visa para ciudadanos mexicanos. Sin embargo, si planeas quedarte por un período más largo o con otros fines, es importante verificar los requisitos específicos de visa para esos casos.</p><br/>
        <p>- Boletos de avión: Es aconsejable contar con boletos de avión de ida y de retorno a México o a un tercer destino. Los agentes de inmigración colombianos pueden solicitar pruebas de tu intención de regresar a México antes de que expire tu estadía autorizada. Tener los boletos de avión confirmados es una forma de demostrar tu compromiso con el cumplimiento de las regulaciones migratorias.</p><br/>
        <p>- Comprobante de alojamiento: Las autoridades pueden pedir comprobante de alojamiento al ingresar a Colombia para asegurarse de que cuentas con un hospedaje seguro y legal. Puedes presentar una reserva de hotel confirmada o una carta de invitación de un residente colombiano. Aunque no siempre lo exigen, es recomendable que lo lleves contigo para evitar inconvenientes.</p><br/>
        <p>-Comprobantes de recursos económicos: Estos comprobantes pueden ser solicitados para garantizar que tienes medios para costear tus gastos durante el viaje, como alojamiento, alimentación y actividades. Puedes presentar estados de cuenta bancarios, tarjetas de crédito con límites suficientes, constancias de empleo o cartas de patrocinio.</p><br/>
        <p>- Seguro de viaje: Aunque no es un requisito obligatorio, contratar un seguro de viaje es fundamental para tu viaje a Colombia. Estarás cubierto ante enfermedades, lesiones y también recibirás ayuda si se presenta pérdida de equipaje, retrasos de vuelo u otros incidentes que puedan impactar tu travesía.</p><br/>
        <p>- Normas de aduana y restricciones: Antes de viajar, asegúrate de informarte sobre las normativas aduaneras de Colombia para evitar problemas en la entrada al país. Algunos productos, como alimentos perecederos y ciertos artículos restringidos, pueden estar sujetos a regulaciones específicas. Cumplir con las normas aduaneras es fundamental para evitar inconvenientes en el proceso de ingreso.</p><br/>
    
       
    
      </div>
    ),
  },

 
];

export default faqs;
