import FaqAccordion from "./FaqAccordion";

const FaqComponent = () => {
  return (
    <div className="w-full mx-auto max-w-[358px] sm:max-w-[633px] sm:mt-20 px-4 xl:mt-12 xl:max-w-[1210px]">
      <FaqAccordion />
    </div>
  );
};

export default FaqComponent;
