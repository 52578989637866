import TestimonialsHeader from "./TestimonialsHeader";
import TestimonialsSlider from "./TestimonialsSlider";

const Testimonials = () => {
  return (
    <div className="mt-20 sm:mt-24 xl:mt-0">
      <TestimonialsHeader />
      <TestimonialsSlider />
    </div>
  );
};

export default Testimonials;
